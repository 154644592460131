<script setup>
const { locale, locales, setLocale } = useI18n()

const availableLocales = computed(() => {
  return (locales.value).filter(i => i.code !== locale.value)
})
</script>

<template>
  <a v-for="l in availableLocales" :key="l.code" href="#" class="py-2" @click.prevent.stop="setLocale(l.code)">
    {{ l.name }}
  </a>
</template>

<style scoped>
a {
  text-align: center;
  font-weight: 400;
}

a:hover {
  background-color: #DBE5DF;
  color: #003C71;
  font-weight: 700;
}
</style>
